

import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AutoLogoffWithPopup = () => {
    const [modalShow, setModalShow] = React.useState(false);

    useEffect(() => {
        let inactivityTimeout;

        const handleUserActivity = () => {
            clearTimeout(inactivityTimeout);
            setInactivityTimeout();
        };

        const setInactivityTimeout = () => {
            inactivityTimeout = setTimeout(() => {
                setModalShow(true);
            }, 5 * 60 * 1000);
        };

        const clearInactivityTimeout = () => {
            clearTimeout(inactivityTimeout);
            setModalShow(false);
        };

        const handleVisibilityChange = () => {
            if (document.hidden) {
                clearInactivityTimeout();
            } else {
                clearInactivityTimeout();
                setInactivityTimeout();
            }
        };

        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('keydown', handleUserActivity);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            clearTimeout(inactivityTimeout);
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('keydown', handleUserActivity);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const handleConfirmation = (confirmed) => {
        setModalShow(false);
    };




    return (
        <div>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="xxl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Session Timeout
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You are being timed-out due to inactivity. Please choose to stay signed in or to logoff.
                        otherwise, you will be logged off automatically.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleConfirmation(true)}>Log Off</Button>
                    <button onClick={() => handleConfirmation(false)} className='create-btn'>Stay Logged In</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AutoLogoffWithPopup;
