import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../services/loader';
import Logoutabha from './header/logoutabha';
import CryptoJS from "crypto-js";
import { post } from '../services/services';
import { toast } from 'react-toastify';
import config from '../config';

function UserLogin() {
  const [showbtn, setshowbtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const storedObject = sessionStorage.getItem('AbhaCreation');
  var abhastorageData = JSON.parse(storedObject);
  // Prod
  const URL="https://api.orientalinsurance.org.in";
  
  //pre-prod 
  // const URL="https://y4132nnj76.execute-api.ap-south-1.amazonaws.com/pre-prod";

  // UAT
  // const URL="https://6uce2slfki.execute-api.ap-south-1.amazonaws.com/dev";
  const apiEndpoint = config.apiEndpoint;
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        var apiUrl;
        if (abhastorageData?.indentifire == "ABHAlogin") {
          apiUrl = `${URL}/api/v1/abha/login/abha-address/abhaAddressCard`;
        } else {
          apiUrl = `${URL}/api/v1/abha/enrollment/abha-card`;
        } if (abhastorageData?.indentifire == "ABHANumber") {
          apiUrl = `${URL}/api/v1/abha/login/phrCard`;
        }
          const encryptData = { payload:CryptoJS.AES.encrypt(abhastorageData?.token, apiEndpoint,{keySize:256}).toString(),}
          const result = await post(apiUrl,encryptData)
          setImageSrc(result);
          setLoading(false);
      }catch(err){
          setLoading(false);
          toast.error("Something Went wrong!")
      }}
    fetchData();
  }, []);


  const createPDF = async () => {
    const pdf = new jsPDF("landscape", "pt", "a3");
    const data = await document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("ABHA Card.pdf");
    });
  };


  const elementToCapture = useRef(null);

  const handleCaptureClick = () => {
    if (elementToCapture.current) {
      html2canvas(elementToCapture.current).then(function (canvas) {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'ABHA Card.png';
        link.click();
      });
    }
  };

  return (
    <>
      <Logoutabha />
      <center>
        <div id="pdf" ref={elementToCapture}>
          {imageSrc ? (
            <img src={`data:image/png;base64,${imageSrc?.file}`} alt="Fetched Image" />
          ) : (
            <p></p>
          )}
        </div>
        {imageSrc ? <>
          {/* <button className='btn mb-4 loginBtn' onClick={createPDF}><i className="fa fa-file-pdf-o"></i> Download PDF</button> */}
          <button className='btn mb-4 loginBtn ' onClick={handleCaptureClick}><i className="fa fa-file-image-o"></i> Download Abha Card</button>
        </>
          : ""}
      </center>
      <ToastContainer />
      {!loading ? "" : <div className="overlay"><Loader /></div>}
    </>
  );
}

export default UserLogin;