import React from "react";
import Header from '../components/header/header';
import "../styles/demo.scss";
import Stepper from "../components/Stepper/stepper";
function Createabha() {

  return (
    <>

      <Header />
      <div className="banner-contianer">
        <Stepper />
      </div>


    </>
  )
}


export default Createabha;