import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Set up Axios with a base URL if needed
const axiosInstance = axios.create({
  // baseURL: 'https://d28ln7m8bf3v8x.cloudfront.net/api'
  baseURL: process.env.REACT_APP_API_BASE_URL
});
axiosInstance.interceptors.request.use(
  (config) => {
    // config.headers['x-api-key'] = "TZYw75SsQptYeiXmVwJ19DQkPtmqkXz47POQE3zj";
    config.headers['x-api-key'] = process.env.REACT_APP_API_ENDPOINT;
    config.headers['host'] = "https://abha.orientalinsurance.org.in";
    config.headers['X-Frame-Options'] = "DENY";
    return config;
  },
  (error) => {
    toast.error(error);
    return Promise.reject(error);
  }
);

export const get = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    return error?.response;
  }
};

export const fetchData = async (url, headers) => {
  try {
    const response = await axiosInstance.get(url, headers);
    return response.data;
  } catch (error) {
    return error?.response;
  }
};


// Reusable POST request function
export const post = async (url, data = {}, headers = {}) => {
  try {
    const response = await axiosInstance.post(url, data, {
      headers: { ...headers },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.status == '500') {
      toast.error('Error code 500 :- Something went wrong!')
    }
    else if (error?.response?.data?.status == '504') {
      toast.error('Error code 500 :-  Gateway Timeout!')
    }
    else {
      toast.error(error?.response?.data?.message);
    }
    throw new Error(error);
  }
};

// export const post = async (url, data = {},headers) => {
//   try {
//     const response = await axiosInstance.post(url, data);
//     return response.data;
//   } catch (error) {
//     if(error?.response?.data?.status == '500'){
//       toast.error('Error code 500 :- Something went wrong!')    
//     }
//     else{
//       toast.error(error?.response?.data?.message);
//     }
//     throw new Error(error);
//   }
// };

// Reusable PATCH request function
export const patch = async (url, data = {}) => {
  try {
    const response = await axiosInstance.patch(url, data);
    return response.data;
  } catch (error) {
    throw new Error(`PATCH request error: ${error.message}`);
  }
};

// Reusable DELETE request function
export const del = async (url) => {
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw new Error(`DELETE request error: ${error.message}`);
  }
};


