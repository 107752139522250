import React from 'react'
import { BounceLoader } from "react-spinners";

export default function loader() {
  return (
    <div className="overlay">
      <BounceLoader color="#ffff" size={60} />
      <h5>Please Wait While Loading...</h5>
    </div>
  )
}
