import CryptoJS from "crypto-js";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../services/loader';
import Header from '../../header/header';
import config from '../../../config';

export default function AbhaCard() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const secretPass = config.apiEndpoint;
    const storedObject = sessionStorage.getItem('AbhaCreation');
    var abhastorageData = JSON.parse(storedObject);
    
    useEffect(() => {
        setUsers(abhastorageData?.accounts)
       }, []);
       
       const verify = async (resp) => {
        setLoading(true);
        var jsonBody = {
          ABHANumber: resp,
          txnId: resp.txnId
        };
        const encryptData = {
          payload:CryptoJS.AES.encrypt(JSON.stringify(jsonBody), secretPass,{keySize:256}).toString(),
         }
        
        await fetch(
          "https://d28ln7m8bf3v8x.cloudfront.net/api/v1/abha/login/verify/user",
      {
            method: "POST",
            body: JSON.stringify({
              encryptData
        }),
            headers: {
              "Content-Type": "application/json",
              "tToken": abhastorageData?.token
            },
          }
        )
          .then((response) => response.json())
          .then((resp) => {
            setLoading(false);
            const abhaDetails = {
              indentifire: "ABHANumber",
              token: resp?.token,
            };
            sessionStorage.setItem("AbhaCreation", JSON.stringify(abhaDetails));
            window.location.href = "/user-Profile";
          
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.message);
          });
      };



    return (
        <>
            <Header />
            <div className="createabha create-abha-card container">
                <div className="profile-card-section">
                    <div className="row">
                      <h5 className='mb-3'>We have found the following ABHA number linked to provided mobile number. Select the ABHA profile you wish to login.</h5>
                      {users && users.map((item, index) => {
                        return (
                    <div className="col-4" key={index}>
                            <div className="card nopadding">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4">
                                            <img src={`data:image/png;base64,${item?.profilePhoto}`}  alt="" />
                                        </div>
                                        <div className="col-8">
                                            <p className='username'>{item?.name ? item?.name : '-'}</p>
                                            <p className={item.verifiedStatus=='VERIFIED' ?'verified' :'pending'}>
                                                {item.verifiedStatus=='VERIFIED' ?
                                                <i class="fa fa-check-circle-o" aria-hidden="true"></i> :
                                                <i class="fa fa-clock-o" aria-hidden="true"></i>}
                                                &nbsp;
                                                {item.verifiedStatus}
                                            </p>

                                            <div className="abha-number">
                                            <label htmlFor="">ABHA Number</label>
                                            <span className='abhanumber'>{item?.ABHANumber ? item?.ABHANumber : '-'}</span>
                                            </div>
                                            <div className="abha-number">
                                            <label htmlFor="">ABHA Address</label>
                                            <span className='abhanumber'> {item?.preferredAbhaAddress ? item?.preferredAbhaAddress : '-' }</span>
                                            </div>
          
                                        </div>
                                    </div>
                                </div>
                                <div className='view' onClick={() => verify(item?.preferredAbhaAddress)}>
                                  <p className='viewprofile'>View Profile <i class="fa fa-arrow-right" aria-hidden="true"></i></p>
                                </div>
                            </div>
                        </div>
                        )})}
                    {/* {users && users.map((item, index) => {
                    return (
                        <div className="col-4" key={index}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-8" onClick={() => verify(item.abhaAddress)}>

                                    <label htmlFor="">Full Name : <span> {item.fullName} </span></label>
                                    <label htmlFor="">Abha Address : <span> {item.abhaAddress} </span> </label>
                                    <label htmlFor="">Kyc Status : <span> {item.kycStatus} </span> </label>
                                    <label htmlFor="">Status : <span> {item.status} </span> </label>
                                    </div>
                                  <div className="col-4">
                                  <img src={`data:image/png;base64,${item?.profilePhoto}`} alt="" />
                                  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )})} */}

                    </div>

                </div> 
            </div>
            {!loading ? "" : <Loader />}
        </>
    )
}
