import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Logoutabha from '../components/header/logoutabha';
import Loader from '../services/loader';
import './userProfile.css';
import CryptoJS from "crypto-js";
import { post } from '../services/services';
import config from '../config';


export default function UserProfile() {
    const [data, setData] = useState([]);
    const [mobilerr, setmobilerr] = useState(true);
    const [loading, setLoading] = useState(false);
  const apiEndpoint = config.apiEndpoint;

   // Prod
   const URL="https://api.orientalinsurance.org.in";
//    pre-prod
//    const URL="https://y4132nnj76.execute-api.ap-south-1.amazonaws.com/pre-prod";
   // UAT
//    const URL="https://6uce2slfki.execute-api.ap-south-1.amazonaws.com/dev";

    const mobilenumber = (e) => {
        const input = e.target.value;
        setData({ mobile: input });
        const isValid = /^[0-9]{10}$/g.test(input);
        setData({ mobile: input });
        setmobilerr(isValid);
    }

    useEffect(() => {
        getuserProfile();
    }, []);

    const getuserProfile = async () => {
        var apiUrl;
        var headers;
        setLoading(true);
        const storedObject = sessionStorage.getItem('AbhaCreation');
        var abhastorageData = JSON.parse(storedObject);

        if (abhastorageData?.indentifire == "ABHAlogin") {
            headers = { 'xToken': abhastorageData?.token }
            apiUrl = `${URL}/api/v1/abha/login/abha-address/profile`;
        }
        else {
            headers = { 'xToken': abhastorageData?.token }
            apiUrl =  `${URL}/api/v1/abha/enrollment/profile`;
        }
        if(abhastorageData?.indentifire == 'ABHANumber')
         {
            headers = { 'xToken': abhastorageData?.token }
            apiUrl =  `${URL}/api/v1/abha/login/profile`;
        }
        try{
            const encryptData = {
                payload:CryptoJS.AES.encrypt(abhastorageData?.token, apiEndpoint,{keySize:256}).toString(),
               }
            const result = await post(apiUrl,encryptData)
            setData(result);
            setLoading(false);
        }catch(err){
            setLoading(false);
            toast.error("Something Went wrong!")
        }
    }


    //     setLoading(true)
    //     try {
    //       const newData = {
    //         number: aadharNumber,
    //         loginType: "",
    //         otpType: "",
    //         otp: "",
    //         txnId: "",
    //         mobileNumber: ""
    //       };
    //         const encryptData = {
    //           payload:CryptoJS.AES.encrypt(JSON.stringify(newData), secretPass,{keySize:256}).toString(),
    //          }
    //       const result = await post('/v1/abha/enrollment/otp/request', encryptData);
    //       const abhaDetails = {
    //         txnId: result?.txnId,
    //         aadharNumber: aadharNumber,
    //         message: result?.message
    //       }
    //       sessionStorage.setItem('AbhaCreation', JSON.stringify(abhaDetails));
    //       const stepCount = 1;
    //       setUserAnswer("")
    //       toast.success(result?.message);
    //       props.nextStep(stepCount);
    //       setLoading(false)
    //     } catch (error) {
    //       setUserAnswer("")
    //       setLoading(false)
    //     }
    //   };
    return (
        <>
            <Logoutabha />
            <div className="container">
                <div className="user-profile">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-sm-12">

                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-6">
                                            <label htmlFor="" className='label-text'><span>ABHA Address</span></label> <br />
                                            <label htmlFor="" className='profile-text'><span>{data?.healthId ? data?.healthId : data?.preferredAbhaAddress}</span></label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-6">
                                            <label htmlFor="" className='label-text'><span>ABHA Number</span></label> <br />
                                            <label htmlFor="" className='profile-text'><span>{data?.healthIdNumber ? data?.healthIdNumber :data?.ABHANumber}</span></label>
                                        </div>
                                    </div><br></br>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-4 ">
                                            <label htmlFor="" className='label-text'><span>Name</span></label> <br />
                                            <label htmlFor="" className='profile-text'><span>{data?.name ? data.name :data.fullName}</span></label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <label htmlFor="" className='label-text'><span>Gender</span></label><br />
                                            <label htmlFor="" className='profile-text'><span>{data.gender}</span></label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <label htmlFor="" className='label-text'><span>Date of birth</span></label><br />
                                            <label htmlFor="" className='profile-text'><span>{data?.dateOfBirth ? data.dateOfBirth : data?.dayOfBirth +"-"+ data?.monthOfBirth +"-"+ data?.yearOfBirth}</span></label>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <label htmlFor="" className='label-text'><span>Address</span></label> <br />
                                            <label htmlFor="" className='profile-text'><span>{data.address}</span></label>
                                        </div>
                                    </div>


                                    <div className="row mt-4">
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <label htmlFor="" className='label-text'><span>District</span></label> <br />
                                            <label htmlFor="" className='profile-text'><span>{data.districtName?data.districtName:'-'}</span></label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <label htmlFor="" className='label-text'><span>State</span></label><br />
                                            <label htmlFor="" className='profile-text'><span>{data.stateName}</span></label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <label htmlFor="" className='label-text'><span>Pincode</span></label><br />
                                            <label htmlFor="" className='profile-text'><span>{data.pincode?data.pincode:data.pinCode}</span></label>
                                        </div>
                                    </div>

                                    

                                    <div className="row mt-4 phonenumber">
                                        
                                    <div className="col-lg-4 col-md-4 col-4">
                                            <label htmlFor="" className='label-text'><span>Mobile number</span></label><br />
                                            <label htmlFor="" className='profile-text'><span>{data.mobile}</span></label>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <div className="profile-box">
                                        <div className="profile-photo">
                                            <img src={`data:image/png;base64,${data?.profilePhoto}`} alt="" />

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='btn btn-primary btn-lg' onClick={() => window.location.href = '/userlogin'}> Get Card</button>

            </div>

            {!loading ? "" : <div className="overlay"><Loader /></div>}

        </>
    )
}
